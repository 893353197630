import React from "react";

function SignIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="35"
      fill="none"
      viewBox="0 0 47 35"
    >
      <path
        fill={color}
        d="M44.064.728l1.792 3.712a45.187 45.187 0 00-4.864 2.816c-1.536 1.11-2.773 2.56-3.712 4.352-.939 1.877-1.408 4.31-1.408 7.296l1.536-.256a6.515 6.515 0 011.28-.128c2.048 0 3.84.683 5.376 2.048 1.45 1.365 2.176 3.2 2.176 5.504 0 2.219-.768 4.181-2.304 5.888-1.621 1.792-3.925 2.688-6.912 2.688-2.56 0-4.95-.896-7.168-2.688-2.304-1.707-3.456-4.907-3.456-9.6 0-3.413.64-6.357 1.92-8.832 1.195-2.475 2.773-4.565 4.736-6.272 1.877-1.707 3.797-3.115 5.76-4.224 1.963-1.024 3.712-1.792 5.248-2.304zm-26.24 0l1.792 3.712a45.187 45.187 0 00-4.864 2.816c-1.536 1.11-2.773 2.56-3.712 4.352-.939 1.877-1.408 4.31-1.408 7.296l1.536-.256a6.515 6.515 0 011.28-.128c2.048 0 3.84.683 5.376 2.048 1.45 1.365 2.176 3.2 2.176 5.504 0 2.219-.768 4.181-2.304 5.888-1.621 1.792-3.925 2.688-6.912 2.688-2.56 0-4.95-.896-7.168-2.688C1.312 30.253.16 27.053.16 22.36c0-3.413.64-6.357 1.92-8.832 1.195-2.475 2.773-4.565 4.736-6.272 1.877-1.707 3.797-3.115 5.76-4.224 1.963-1.024 3.712-1.792 5.248-2.304z"
      ></path>
    </svg>
  );
}

export default SignIcon;
