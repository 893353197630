import React from "react";
import { LinkedInIcon } from "../../assets/Icons";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const OurTeam = () => {
  const teamList = [
    {
      img: "./images/DivyanshuGupta.png",
      name: "Divyanshu Gupta",
      desination: "Founder & CEO",
      linkedInUrl: "www.linkedin.com/in/divyanshugupta14",
      color: "#EE8C02",
    },
    {
      img: "./images/PratikshaJena.png",
      name: "Pratiksha Jena",
      desination: "Lead Marketing & Strategy",
      linkedInUrl: "www.linkedin.com/in/pratikshajena",
      color: "#59C4F6",
    },
    {
      img: "./images/SanyogitaGrover.png",
      name: "Sanyogita Grover",
      desination: "Lead Data Scientist",
      linkedInUrl: "www.linkedin.com/in/sanyogitagrover",
      color: "#64C466",
    },
    {
      img: "./images/JiyaGoel.png",
      name: "Jiya Goel",
      desination: "Lead Finance & Strategy",
      linkedInUrl: "www.linkedin.com/in/jiyagoel",
      color: "#64C466",
    },
    {
      img: "./images/ArushiGupta.png",
      name: "Arushi Gupta",
      desination: "Lead Counselling Psychologist",
      linkedInUrl: "www.linkedin.com/in/arushigupta311/",
      color: "#EE8C02",
    },
    {
      img: "./images/DhruvNenwani.png",
      name: "Dhruv Nenwani",
      desination: "Lead Software Engineer",
      linkedInUrl: "www.linkedin.com/in/dhruvnenwani",
      color: "#59C4F6",
    },
    {
      img: "./images/ShwetaSharma.png",
      name: "Shweta Sharma",
      desination: "Associate Counselling Psychologist",
      linkedInUrl: "www.linkedin.com/in/shweta-sharma-71b3b2192",
      color: "#59C4F6",
    },
    {
      img: "./images/AmitSarkar.png",
      name: "Amit Sarkar",
      desination: "Data Scientist I",
      linkedInUrl: "www.linkedin.com/in/amit-sarkar-8551131a4",
      color: "#64C466",
    },
    {
      img: "./images/AnshSaxena.png",
      name: "Ansh Saxena",
      desination: "Visual Media Specialist",
      linkedInUrl: "www.linkedin.com/in/ansh-saxena-7b1938225",
      color: "#EE8C02",
    },
  ];
  return (
    <div className="our-team flex bg-themeColor2 sm:py-32 py-10 min-h-[528px]">
      <div className="wrap wrapWidth flex flex-col items-center justify-center sm:gap-6 gap-1">
        <h1 className="h1 sm:text-5xl text-4xl w-max text-center sm:mb-10 mb-2">
          Our Team.
        </h1>
        <div className="w-full hidden sm:grid md:grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-12 px-24">
          {teamList.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-4"
            >
              <a
                href={"https://" + item.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`flex items-center justify-center h-[132px] w-[132px] rounded-full border-4 overflow-hidden`}
                style={{ borderColor: item.color, borderStyle: "solid" }}
              >
                <img src={item.img} className="h-full w-full object-cover" />
              </a>
              <div className="flex flex-col gap-2 items-center">
                <div className="flex items-center gap-3 justify-between">
                  <h1 className="h2 text-2xl">{item.name}</h1>
                  <a
                    href={"https://" + item.linkedInUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center h-5 w-5"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
                <h3 className="h3 text-base">{item.desination}</h3>
              </div>
            </div>
          ))}
        </div>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="team-swiper flex md:!hidden sm:!hidden"
        >
          {teamList.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center justify-center gap-4 py-5 px-2">
                <a
                  href={"https://" + item.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`flex items-center justify-center sm:h-28 sm:w-28 h-[169px] w-[169px] rounded-full border-4 overflow-hidden`}
                  style={{
                    border: "6px",
                    borderColor: item.color,
                    borderStyle: "solid",
                  }}
                >
                  <img src={item.img} className="h-full w-full object-cover" />
                </a>
                <div className="flex flex-col items-center gap-2">
                  <div className="flex items-center gap-3 justify-between">
                    <h1 className="h6 sm:text-xl text-[27px]">{item.name}</h1>
                    <a
                      href={"https://" + item.linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center"
                    >
                      <LinkedInIcon />
                    </a>
                  </div>
                  <h3 className="h3 text-base venir-r ">{item.desination}</h3>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurTeam;
