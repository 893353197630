import React from "react";

function PlayStoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#000"
      viewBox="0 0 24 24"
    >
      <path d="M2.25 2.788V21.21a.203.203 0 00.345.144L12.187 12 2.595 2.644a.203.203 0 00-.345.144zM16.21 8.156L4.183 1.53l-.007-.004c-.208-.113-.404.168-.235.33l9.428 9.016 2.841-2.716zM3.942 22.14c-.17.164.026.444.234.332l.008-.004L16.21 15.84l-2.841-2.716-9.427 9.016zM21.065 10.826l-3.358-1.85-3.158 3.022 3.158 3.02 3.358-1.848c.914-.505.914-1.839 0-2.344z"></path>
    </svg>
  );
}

export default PlayStoreIcon;
