import React, { useEffect } from "react";

import Wrapper from "../../routes/Wrapper";
import HeroSection from "./HeroSection";
import FirstSlider from "./FirstSlider";
import WhatHearing from "./WhatHearing";
import TechnologySection from "./TechnologySection";
import WhitelistForm from "./WhitelistForm";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <div className="lading-page overflow-hidden relative">
        <HeroSection />
        <FirstSlider />
        <WhatHearing />
        <TechnologySection />
        <WhitelistForm />
      </div>
    </Wrapper>
  );
};

export default Main;
