import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CloseIcon } from "../../assets/Icons";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const navBarItems = [
    { lbl: "About us", slug: "/aboutus" },
    {
      lbl: "Community",
      slug: "https://chat.whatsapp.com/CaJADzzVi483r7vQrI2Y6o",
    },
    { lbl: "We’re Hiring", slug: "https://thinkle.notion.site/We-re-Hiring-4e211f3c46094ca7905fa6c25fb69fcd" },
    { lbl: "Contact Us", slug: "mailto:work@thinkle.xyz" },
  ];

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      setOpenSidebar(false);
    });
  }, []);

  return (
    <div className={`sidebar-s fixed rel  ${openSidebar ? "show" : "hide"}`}>
      <div
        className={`side-block flex  ${openSidebar ? "show" : "hide"}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="wrapWidth flex flex-col">
          <div className="hdr flex items-center justify-between">
            <div className="hdr-tag">
              <img src="./images/Logo_Black.png" className="logo" />
            </div>
            <div
              className="icon-close flex aic jc"
              onClick={(e) => {
                setOpenSidebar(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div>
            <div className="menu-items flex items-center flex-col">
              {navBarItems.map((item, index) => (
                <Link
                  to={item.slug}
                  className={`item flex `}
                  onClick={(e) => {
                    setOpenSidebar(false);
                  }}
                >
                  <div className="li">{item.lbl}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
