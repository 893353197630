import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M16.375 3.25a4.388 4.388 0 014.375 4.375v8.75a4.388 4.388 0 01-4.375 4.375h-8.75a4.389 4.389 0 01-4.375-4.375v-8.75A4.388 4.388 0 017.625 3.25h8.75zm0-1.75h-8.75C4.256 1.5 1.5 4.256 1.5 7.625v8.75c0 3.369 2.756 6.125 6.125 6.125h8.75c3.369 0 6.125-2.756 6.125-6.125v-8.75c0-3.369-2.756-6.125-6.125-6.125z"></path>
      <path d="M17.688 7.625a1.313 1.313 0 110-2.625 1.313 1.313 0 010 2.625zM12 8.5a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm0-1.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z"></path>
    </svg>
  );
}

export default InstagramIcon;
