import React from "react";

const AllThoseWho = () => {
  return (
    <div className="all-those-who flex bg-white sm:py-28 py-20 sm:min-h-[300px] min-h-[200px]">
      <div className="wrap wrapWidth flex justify-center">
        <div className="flex flex-col sm:gap-8 gap-4 w-max">
          <h1 className="h1 sm:text-[26px] text-xl">To all those who</h1>
          <h1 className="h1 sm:text-[26px] text-xl">
            <span className="bg-[#46b5f7] p-1">walked</span> with us,
          </h1>
          <h1 className="h1 sm:text-[26px] text-xl">
            <span className="bg-[#eb539f] p-1">taught</span> us, and
          </h1>
          <h1 className="h1 sm:text-[26px] text-xl">
            {" "}
            <span className="bg-[#ee8c02] p-1">inspired</span> us,
          </h1>
          <h1 className="h1 sm:text-[26px] text-xl">this journey is</h1>
          <h1 className="h1 sm:text-[26px] text-xl">
            as much yours, as it is ours.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AllThoseWho;
