import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const WhatWe = () => {
  return (
    <div className="what-we flex bg-white py-20 sm:min-h-[560px] min-h-[528px]">
      <div className="wrap wrapWidth flex flex-col items-center justify-center">
        <div className="w-full hidden sm:grid md:grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
          <div className="flex flex-col items-center p-3 gap-8">
            <h1 className="bg-[#59C4F6] p-1 h4 text-[27px] w-max">
              Who are we?
            </h1>
            <p className="h5 text-lg leading-5">
              Team of Psychologists, Data Scientists, Career Coaches and Tech
              Enthusiasts driven by a shared mission — transform career
              discovery.
              <br />
              <br />
              At our core, we're lifelong students inspired by our own career
              journeys.
            </p>
          </div>
          <div className="flex flex-col items-center p-3 gap-8">
            <h1 className="bg-[#EE8C02] p-1 h4 text-[27px] w-max">
              What we do?
            </h1>
            <p className="h5 text-lg leading-5">
              Blending data-driven algorithms with deep human insights making
              career guidance more objective, personalised & accessible.
              <br />
              <br />
              No more guesswork, just clear paths forward.
            </p>
          </div>
          <div className="flex flex-col items-center p-3 gap-8">
            <h1 className="bg-[#EB539F] p-1 h4 text-[27px] w-max">
              Why we do it?
            </h1>
            <p className="h5 text-lg leading-5">
              The key to our dream careers are often gated by the schools we
              went to, the society we grew up in, or the people we know.
              <br />
              <br />
              But, what if everyone had a level playing field?
            </p>
          </div>
        </div>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper flex md:!hidden sm:!hidden"
        >
          <SwiperSlide>
            <div className="flex flex-col items-center p-3 gap-10">
              <h1 className="bg-[#59C4F6] p-1 h4 sm:text-xl text-[27px] w-max">
                Who are we?
              </h1>
              <p className="h5 text-lg leading-5 text-justify">
                Team of Psychologists, Data Scientists, Career Coaches and Tech
                Enthusiasts driven by a shared mission — transform career
                discovery.
                <br />
                <br />
                At our core, we're lifelong students inspired by our own career
                journeys.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center p-3 gap-10">
              <h1 className="bg-[#EE8C02] p-1 h4 sm:text-xl text-[27px] w-max">
                What we do?
              </h1>
              <p className="h5 text-lg leading-5 text-justify">
                Blending data-driven algorithms with deep human insights making
                career guidance more objective, personalised & accessible.
                <br />
                <br />
                No more guesswork, just clear paths forward.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center p-3 gap-10">
              <h1 className="bg-[#EB539F] p-1 h4 sm:text-xl text-[27px] w-max">
                Why we do it?
              </h1>
              <p className="h5 text-lg leading-5 text-justify">
                The key to our dream careers are often gated by the schools we
                went to, the society we grew up in, or the people we know.
                <br />
                <br />
                But, what if everyone had a level playing field?
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default WhatWe;
