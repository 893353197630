import React from "react";
import { AppStoreIcon, PlayStoreIcon } from "../../assets/Icons";

const HeroSection = () => {
  return (
    <div className="hero-section bg-themeColor2 h-screen flex">
      <div className="wrap wrapWidth flex items-center justify-center flex-col">
        <h2 className="the-f-of heading1  text-black sm:mb-3  mb-0">
          the future of
        </h2>
        <h1 className="career-d text-black sm:text-6xl text-[40px] font-medium h1 mb-10">
          Career Discovery.
        </h1>
        <button className="flex items-center gap-3 btn py-4 px-5 bg-white rounded-full">
          {/* <img src="./images/playstore.png" className="h-6 w-6" /> */}
          <div className="flex items-center justify-center">
            <PlayStoreIcon />
          </div>
          <p className="text-black h2">COMING SOON</p>
          {/* <img src="./images/apple.png" className="h-6 w-6" /> */}
          <div className="flex items-center justify-center">
            <AppStoreIcon />
          </div>
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
