import React from "react";

const AboutHero = () => {
  return (
    <div className="about-hero flex items-center justify-center bg-themeColor2 md:h-screen h-[528px]">
      <div className="wrap wrapWidth flex flex-col gap-1 items-center justify-center">
        <h2 className="h1 font-semibold sm:text-[42px] text-[22px]">
          your career should be an
        </h2>
        <h1 className="h1 sm:text-[60px] text-[28px]">
          <span className="bg-[#63c467] text-black px-2">adventure</span> not a{" "}
          <span className="bg-[#e94f3d] text-black px-2">puzzle.</span>
        </h1>
      </div>
    </div>
  );
};

export default AboutHero;
