import React, { useState, useEffect } from "react";

import Wrapper from "../../routes/Wrapper";
import AboutHero from "./AboutHero";
import WhatWe from "./WhatWe";
import OurTeam from "./OurTeam";
import AllThoseWho from "./AllThoseWho";
import WhitelistForm from "../Home/WhitelistForm";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <div className="about-us-page relative overflow-hidden">
        <AboutHero />
        <WhatWe />
        <OurTeam />
        <AllThoseWho />
        <WhitelistForm />
      </div>
    </Wrapper>
  );
};

export default AboutUs;
