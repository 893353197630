import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  InstagramIcon,
  TwitterIcon,
  LinkedInIcon,
  WhatsAppIcon,
  LinkedInIcon2,
} from "../../assets/Icons";

const Footer = () => {
  return (
    <div className="footer-comp flex flex-col bg-white gap-6">
      <div className="f-top flex items-start">
        <div className="wrap wrapWidth flex justify-between sm:flex-row flex-col gap-6">
          <div className="flex flex-col gap-6">
            <Link
              to="/"
              className="logo-img flex sm:items-start items-start sm:justify-start justify-start"
            >
              <img src="../images/Logo_Black.png" className="logo" />
            </Link>
            <div className="flex flex-col gap-3">
              <Link
                to="/aboutus"
                className="text-black/70 text-base h3 font-light"
              >
                About us
              </Link>
              <a href="/" className="text-black/70 h3 text-base h2">
                Join our Community
              </a>
              <a href="https://thinkle.notion.site/We-re-Hiring-4e211f3c46094ca7905fa6c25fb69fcd" className="text-black/70 h3 text-base h2">
                We're Hiring
              </a>
              <a href="mailto:work@thinkle.xyz" className="text-black/70 h3 text-base h2">
                Contact us
              </a>
            </div>
          </div>
          <div className="social flex gap-6">
            <div className="icon flex items-center justify-center">
            <Link
                to="https://www.instagram.com/thinklehq/"
              > <InstagramIcon />
              </Link>
            </div>
            <div className="icon flex items-center justify-center">
            <Link
                to="https://www.linkedin.com/company/thinklecareers/"
              >
              <LinkedInIcon2 />
              </Link>
            </div>
            <div className="icon flex items-center justify-center">
            <Link
                to="https://chat.whatsapp.com/CaJADzzVi483r7vQrI2Y6o"
              >
              <WhatsAppIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="f-btm flex">
        <div className="wrap wrapWidth flex gap-8">
          <h1 className="text-black/50 h3 text-xs">Copyright ©</h1>
          <h1 className="text-black/50 h3 text-xs">
            Thinkle Technologies Private Limited
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
