import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { SignIcon } from "../../assets/Icons";

const WhatHearing = () => {
  return (
    <div className="what-hearing-section flex bg-themeColor2 py-24 min-h-[528px]">
      <div className="wrap flex flex-col items-center justify-center gap-12 sm:px-0 px-3">
        <h1 className="what-w-been h1 sm:text-[46px] text-[28px]">
          What we’ve been hearing.
        </h1>
        <Swiper
          modules={[Pagination, Autoplay]}
          className="mySwiper bg-themeColor2"
          slidesPerView={4.5}
          spaceBetween={20}
          //   pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            1024: {
              slidesPerView: 4.5,
            },
            768: {
              slidesPerView: 3.5,
            },
            640: {
              slidesPerView: 2.5,
            },
            480: {
              slidesPerView: 2.2,
            },
            340: {
              slidesPerView: 1.2,
            },
          }}
        >
          <SwiperSlide>
            <div className="flex flex-col gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#64C466" />
              </div>
              <p className="h2 text-base text-start leading-5 sm:min-h-[105px] min-h-[90px]">
                Finally a platform that addresses careers for what they really
                are instead of just listing jobs or selling courses. Really
                excited for what’s coming.
              </p>
              <h2 className="h3 text-sm text-start">
                Arun, Software Developer
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="">
                <SignIcon color="#295FF4" />
              </div>
              <p className="h2 text-base text-start leading-5 sm:min-h-[105px] min-h-[90px]">
                I’ve been craving a tool that understands the nuances of
                careers. Can’t wait to try out Thinkle.
              </p>
              <h2 className="h3 text-sm text-start">Kirti, Master’s student</h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#EA4D3D" />
              </div>
              <p className="h2 text-base text-start leading-5 sm:min-h-[105px] min-h-[90px]">
                A platform which helps people make smarter, more informed career
                decisions?! I’m all for it
              </p>
              <h2 className="h3 text-sm text-start">
                Sakshi, Undergrad Student
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#295FF4" />
              </div>
              <p className="h2 text-base text-start leading-5 sm:min-h-[105px] min-h-[90px]">
                I’ve seen too many people sold false dreams by so called “career
                experts” and organisations looking to cash in on aspirations.
              </p>
              <h2 className="h3 text-sm text-start">Joydeep, Parent</h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#64C466" />
              </div>
              <p className="h2 text-base text-start leading-5 sm:min-h-[105px] min-h-[90px]">
                Navigating through my career has always felt overwhelming. I
                wish thinkle was around years ago!
              </p>
              <h2 className="h3 text-sm text-start">
                Vikram , Business Analyst
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#EA4D3D" />
              </div>
              <p className="h2 text-base text-start leading-5 sm:min-h-[105px] min-h-[90px]">
                As someone who’s felt stuck in a career rut, the idea of Thinkle
                offering clear, tailored guidance is a breath of fresh air.
              </p>
              <h2 className="h3 text-sm text-start">
                Shreya, Product Marketer
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#295FF4" />
              </div>
              <p className="h2 text-base text-start leading-5">
                Fear of failure and mental barriers are a common career
                struggle, yet rarely acknowledged. Refreshing to see Thinkle
                challenge the status quo.
              </p>
              <h2 className="h3 text-sm text-start sm:mt-8 mt-1">
                Naveen, Career Coah
              </h2>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#EA4D3D" />
              </div>
              <p className="h2 text-base text-start leading-5">
                Thinkle comes in at a time when the Indian EdTech sector has
                largely overlooked career discovery.
              </p>
              <h2 className="h3 text-sm text-start sm:mt-8 mt-1">
                Soonakshi Gupta
              </h2>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col  gap-2">
              <div className="h1 text-4xl">
                <SignIcon color="#295FF4" />
              </div>
              <p className="h2 text-base text-start leading-5">
                Thinkle comes in at a time when the Indian EdTech sector has
                largely overlooked career discovery.
              </p>
              <h2 className="h3 text-sm text-start sm:mt-8 mt-1">
                Soonakshi Gupta
              </h2>
            </div>
          </SwiperSlide> */}
        </Swiper>
        <div className="flex items-center justify-center">
          <a
            href="#form"
            className="avenir-r btn bg-black rounded-full sm:py-[10px] sm:px-8 py-3 px-10 text-base min-w-[180px] text-center"
          >
            Join the Club
          </a>
        </div>
      </div>
    </div>
  );
};

export default WhatHearing;
