import React from "react";
import { Link } from "react-router-dom";
import { MenuIcon } from "../../assets/Icons";

const Header = ({ setOpenSidebar }) => {
  return (
    <div className="header-camp flex bg-themeColor2">
      <div className="wrapWidth wrap flex items-center gap-5">
        <div className="left flex items-center">
          <Link to="/" className="logo-img flex items-center justify-center">
            <img src="../images/Logo_Black.png" className="logo" />
          </Link>
        </div>
        <div className="right flex items-center justify-end sm:gap-8 gap-5">
          <div className="menu-list md:flex hidden items-center gap-8">
            <Link to="/aboutus" className="menu-item">
              About Us
            </Link>
            <div className="menu-item">
              <Link to="https://chat.whatsapp.com/CaJADzzVi483r7vQrI2Y6o">
                Community
              </Link>
            </div>
            <div className="menu-item">
              <Link to="https://thinkle.notion.site/We-re-Hiring-4e211f3c46094ca7905fa6c25fb69fcd">
                We’re Hiring
              </Link>
            </div>
          </div>

          <a
            href="#form"
            className="join-btn avenir-r btn bg-black rounded-3xl sm:py-[10px] sm:px-10 py-[10px] px-[22px] sm:text-base text-sm z-10 font-black"
          >
            Join Waitlist
          </a>
          <div
            className="flex items-center justify-center cursor-pointer md:hidden"
            onClick={(e) => {
              e.stopPropagation();
              setOpenSidebar(true);
            }}
          >
            <MenuIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
