import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import Select from "react-select";

import Confetti from "react-confetti";
import { createFormValidation } from "../../utiles/formValidations";

const WhitelistForm = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const options = [
    {
      value: "Pursuing a Bachelor's Degree",
      label: "Pursuing a Bachelor's Degree",
    },
    {
      value: "Pursuing a Master's Degree",
      label: "Pursuing a Master's Degree",
    },
    {
      value: "Pursuing a Doctoral Degree",
      label: "Pursuing a Doctoral Degree",
    },
    { value: "Seeking a Job/Internship", label: "Seeking a Job/Internship" },
    {
      value: "Employed in a Job/Profession",
      label: "Employed in a Job/Profession",
    },
    { value: "Self-Employed/Freelancer", label: "Self-Employed/Freelancer" },
    { value: "On a Career Break", label: "On a Career Break" },
  ];

  const initialValues = {
    name: "",
    age: "",
    email: "",
    location: "",
    careerStage: "",
  };
  const onSubmit = async (values, { resetForm }) => {
    setIsSuccess(true);
    axios
      .post("https://lendingapi.thinkle.xyz/api/users", {
        values,
      })
      .then((response) => {
        if (response.status === 201) {
          resetForm();
          setSelectedOption("");
          setIsSuccess(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {});
  };
  const formik = useFormik({
    initialValues,
    validationSchema: createFormValidation,
    onSubmit,
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      maxHeight: "auto",
      overflowY: "auto",
      borderColor: state.isFocused ? "#FCEB00" : "#FCEB00", // Customize border color based on focus state
      boxShadow: state.isFocused ? "0 0 0 1px #FCEB00" : "none", // Add box shadow for focus effect
      "&:hover": {
        borderColor: "#FCEB00", // Customize border color on hover
      },
    }),
  };
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    setFieldValue,
    errors,
  } = formik;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccess(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isSuccess]);

  return (
    <div
      id="form"
      className="whitelist-form flex bg-themeColor2 py-32 relative  overflow-hidden"
    >
      {isSuccess ? (
        <Confetti
          // width={window.innerWidth}
          // height={window.innerHeight}
          height={963}
          className="confetti"
          tweenDuration={500}
        />
      ) : null}
      <div className="wrap wrapWidth flex flex-col items-center justify-center">
        <h1 className="h1 text-black sm:text-5xl text-4xl sm:mb-6 mb-1">
          Join Waitlist.
        </h1>
        <p className="text-black sm:text-lg text-base text-center sm:mb-0 mb-5">
          Sign up for early access to our platform, career resources and
          exclusive content.
        </p>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col sm:w-[400px] w-full gap-5 my-10"
        >
          <div className="flex flex-col gap-1">
            <label className="text-black text-lg h4">What is your name?</label>
            <input
	      type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              isError={errors.name && formik.touched.name}
              placeholder="Name"
              autoComplete="off"
              className="avenir-r  text-sm bg-white px-2 py-[6px] border anim rounded-md focus:border-black text-black"
            />
            {errors.name && formik.touched.name && (
              <div className="text-red-500 text-sm">{errors.name}</div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-black text-lg h1">How old are you?</label>
            <input
              type="number"
              name="age"
              placeholder="Age"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.age}
              autoComplete="off"
              isError={errors.age && formik.touched.age}
              className="avenir-r  text-sm bg-white px-2 py-[6px] border anim rounded-md focus:border-black text-black"
            />

            {errors.age && formik.touched.age && (
              <div className="text-red-500 text-sm">{errors.age}</div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-black text-lg h1">
              What is your email address?
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              isError={errors.email && formik.touched.email}
              autoComplete="off"
              placeholder="Email"
              className="avenir-r  text-sm bg-white px-2 py-[6px] border anim rounded-md focus:border-black text-black"
            />

            {errors.email && formik.touched.email && (
              <div className="text-red-500 text-sm">{errors.email}</div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-black text-lg h1">Where do you live?</label>
            <input
              type="text"
              name="location"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.location}
              isError={errors.location && formik.touched.location}
              autoComplete="off"
              placeholder="City, Country"
              className="avenir-r  text-sm bg-white px-2 py-[6px] border anim rounded-md focus:border-black text-black"
            />

            {errors.location && formik.touched.location && (
              <div className="text-red-500 text-sm">{errors.location}</div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-black text-lg h1">
              Where are you in your career journey?
            </label>
            <Select
              defaultValue={selectedOption}
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e);
                setFieldValue("careerStage", e.label);
              }}
              options={options}
              placeholder="Select an option"
              styles={customStyles}
              className="text-sm avenir-r"
              isSearchable={false}
            />
            {errors.careerStage && formik.touched.careerStage && (
              <div className="text-red-500 text-sm">{errors.careerStage}</div>
            )}
          </div>
          <div className="flex items-center justify-center sm:mt-0 mt-6 mb-10">
            <button
              type="submit"
              className="avenir-r btn bg-black rounded-full w-max !py-[12px] !px-16"
            >
              {isSuccess ? "Thank you" : "SIGN UP"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WhitelistForm;
