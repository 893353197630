import * as Yup from "yup";

export const createFormValidation = Yup.object({
  name: Yup.string().required("Please enter your full name."),
  age: Yup.number()
    .required("Please enter a valid age.")
    .positive("Please enter a valid age."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter a valid email address."),
  location: Yup.string().required("Please enter your current location."),
  careerStage: Yup.string().required("Please select an option from the list."),
});
