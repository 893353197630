import React from "react";

function LinkedIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      fill="none"
      viewBox="0 0 24 23"
    >
      <path
        fill="#fff"
        d="M1.24 7.64h3.84V23H1.24V7.64zM.696 2.424c0-.619.224-1.152.672-1.6.47-.47 1.056-.704 1.76-.704s1.29.224 1.76.672c.49.427.736.97.736 1.632 0 .661-.245 1.216-.736 1.664-.47.427-1.056.64-1.76.64s-1.29-.224-1.76-.672C.92 3.586.696 3.043.696 2.424zM9.489 7.64h3.647v2.464h.065c.362-.768.917-1.43 1.663-1.984.747-.576 1.76-.864 3.04-.864 1.024 0 1.889.17 2.593.512.725.341 1.311.79 1.76 1.344.447.555.767 1.195.96 1.92.192.725.288 1.483.288 2.272V23h-3.84v-7.776c0-.427-.022-.896-.064-1.408a4.655 4.655 0 00-.352-1.472 2.518 2.518 0 00-.832-1.152c-.385-.32-.907-.48-1.569-.48-.64 0-1.183.107-1.631.32a3.31 3.31 0 00-1.088.864c-.278.363-.48.779-.608 1.248a5.57 5.57 0 00-.193 1.472V23h-3.84V7.64z"
      ></path>
    </svg>
  );
}

export default LinkedIcon2;
