import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

const FirstSlider = () => {
  return (
    <div className="first-slider-section flex bg-white sm:py-28 py-14 sm:h-[745px] h-[400px]">
      <div className="wrap wrapWidth flex items-center justify-center">
        <Swiper
          // pagination={true}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center gap-8 h-[85%]">
              <h1 className="h1 sm:text-[86px] text-6xl bg-themeColor2 px-2 py-2 w-max">
                93%
              </h1>
              <p className="h2 sm:text-[46px] text-2xl w-full sm:leading-[58px]">
                Indian students are aware of <br />
                <span className="bg-[#eb539f] text-black px-2">
                  only 7
                </span>{" "}
                career options.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center gap-8 h-[85%]">
              <h1 className="h1 sm:text-[86px] text-6xl bg-themeColor2 px-2 py-2 w-max">
                300 million
              </h1>
              <p className="h2 sm:text-[46px] text-2xl w-full sm:leading-[58px]">
                Jobs expected to be replaced by <br />
                <span className="bg-[#eb539f] text-black px-2">
                  artificial intelligence
                </span>{" "}
                by 2030.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center gap-8 h-[85%]">
              <h1 className="h1 sm:text-[86px] text-6xl bg-themeColor2 px-2 py-2 w-max">
                88%
              </h1>
              <p className="h2 sm:text-[46px] text-2xl w-full sm:leading-[58px]">
                Professionals in India are considering a <br />
                <span className="bg-[#eb539f] text-black px-2">
                  job change
                </span>{" "}
                in 2024.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default FirstSlider;
