import React from "react";

const TechnologySection = () => {
  return (
    <div className="technology-section bg-white sm:min-h-[528px] min-h-[340px] flex sm:py-5 py-24">
      <div className="wrap wrapWidth flex flex-col items-center justify-center">
        <div className="flex flex-col gap-5">
          <h1 className="h1 text-black sm:text-[50px] text-[34px]">
            Technology that,
          </h1>
          <div className="flex flex-col gap-5">
            <h2 className="h6 sm:text-2xl text-[18px] font-bold">
              Understands{" "}
              <span className="bg-[#eb539f] text-black px-2">you</span> for who
              you are.
            </h2>
            <h2 className="h6 sm:text-2xl text-[18px] font-bold">
              Your{" "}
              <span className="bg-[#e94f3d] text-black px-2 leading-4">
                career
              </span>{" "}
              for what it’s been.
            </h2>
            <h2 className="h6 sm:text-2xl text-[18px] font-bold">
              Your{" "}
              <span className="bg-[#63c467] text-black px-2 leading-4">
                goals
              </span>{" "}
              for where you want to be.
            </h2>
          </div>
        </div>
        <a
          href="#form"
          // className="btn bg-themeColor2 text-black rounded-full mt-8 sm:py-[10px] sm:px-8 py-3 px-10 h6  sm:text-lg text-xs"
          className="avenir-r btn bg-themeColor2 text-black rounded-full sm:py-[10px] sm:px-8 py-3 px-10 text-base mt-10 min-w-[180px] text-center"
        >
          EARLY ACCESS
        </a>
      </div>
    </div>
  );
};

export default TechnologySection;
